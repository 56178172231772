// NOTE: this script must be included as part of any
// pack that imports dynamic chunks and it must be
// imported before any other code that imports dynamic
// chunks.
//
// If we're passing a CDN path from the page template,
// use the special `__webpack__public_path__` global
// to set the public path to the CDN path so that dynamic
// chunks will be loaded from the CDN.
//
// Details here: https://webpack.js.org/guides/public-path/#on-the-fly
if (window.__cdn_packs_path__) {
  // eslint-disable-next-line camelcase, no-undef
  __webpack_public_path__ = window.__cdn_packs_path__;
}

