export async function localizeSharedMessages(locale) {
  const addToWebProjectButtonMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/add-to-web-project-button/i18n/${locale}.json`);

  const addToWebProjectConfirmationDialog = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/add-to-web-project/add-to-web-project-confirmation-dialog/i18n/${locale}.json`);

  const addToWebProjectConfirmationFamily = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/add-to-web-project/add-to-web-project-confirmation-family/i18n/${locale}.json`);

  const addToWebProjectDialog = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/add-to-web-project/add-to-web-project-dialog/i18n/${locale}.json`);

  const addToWebProjectFamily = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/add-to-web-project/add-to-web-project-family/i18n/${locale}.json`);

  const favoriteButtonMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/favorite-button/i18n/${locale}.json`);

  const feedbackButtonMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/feedback-button/i18n/${locale}.json`);

  const navbarHeaderMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/navbar-header/i18n/${locale}.json`);

  const navbarSubnavMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/navbar-subnav/i18n/${locale}.json`);

  const searchbarMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/search-bar/i18n/${locale}.json`);

  const sideNavMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/side-navigation/i18n/${locale}.json`);

  const duplicateLibraryElementDialogMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/libraries/duplicate-library-element-dialog/i18n/${locale}.json`);

  const librariesLoadErrorDialog = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/libraries/libraries-load-error-dialog/i18n/${locale}.json`);

  const librariesToolbarMessages = await import(
        /* webpackChunkName: "i18n-[request]" */
        `@adobe-fonts/site/components/libraries/libraries-toolbar/i18n/${locale}.json`);

  const libraryErrorDialogMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/libraries/library-error-dialog/i18n/${locale}.json`);

  const newLibraryDialogMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/libraries/new-library-dialog/i18n/${locale}.json`);

  const saveLibraryIconMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/libraries/save-library-icon/i18n/${locale}.json`);

  const saveLibraryMenuMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/libraries/save-library-menu/i18n/${locale}.json`);

  return {
    ...addToWebProjectButtonMessages,
    ...addToWebProjectConfirmationDialog,
    ...addToWebProjectConfirmationFamily,
    ...addToWebProjectDialog,
    ...addToWebProjectFamily,
    ...favoriteButtonMessages,
    ...feedbackButtonMessages,
    ...navbarHeaderMessages,
    ...navbarSubnavMessages,
    ...searchbarMessages,
    ...sideNavMessages,
    ...duplicateLibraryElementDialogMessages,
    ...librariesLoadErrorDialog,
    ...librariesToolbarMessages,
    ...libraryErrorDialogMessages,
    ...newLibraryDialogMessages,
    ...saveLibraryIconMessages,
    ...saveLibraryMenuMessages
  };
}
